/* Provide sufficient contrast against white background */
body {
    font-family: Roboto, Arial, sans-serif;
}
.accordion-button{
    border-radius: 10px;
    padding: 2% 1%;
    width: 100%;
    font-family: "Arial", sans-serif;
    color: white;
}

.nav-tabs button{
    width: 100%;
    padding: 3% 3%;
}

.paymentInfo{
    color: black !important;
    font-size: 15pt !important;
}

.noRadiusTop{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    background-color: white !important;
}

.whiteBackground{
    background-color: white !important;
}

.titles-dm{
    font-family: Roboto, Arial, sans-serif;
    font-size: 17pt !important;
    color: black !important;
}
.GBHeader ul{
    list-style: none;
    margin-bottom: 0;
}
.GBHeader ul > li{
    display: inline-block;
    margin: 2% 4% 0% 4%;
}
.GBHeader{
box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
background-color: white;
}

.GBFooterText{
    font-weight : 500 !important;
    font-size: 14pt !important
}

.GBFooterText2{
    font-weight : 500 !important;
    font-size: 15pt !important
}

.GBQuestionMark{
    cursor: pointer;
}

.tempClass{
    color: #fff !important;
    font-size: 24px !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-radius: 10px !important;
}

.GBTitle {
    color: #385d79 !important;
    font-size: 40px;
    text-align: center;
    margin: 2% auto;
}

.subTitle1 {
    color: red !important;
    font-size: 40px;
    text-align: end;
    font-weight: bold;
}

.subTitle2 {
    color: red !important;
    font-size: 70px;
    font-weight: bold;
}

.subTitle3 {
    color: red !important;
    font-size: 60px;
    font-weight: bold;
}

.subtitles {
    display: block;
}

@media only screen and (max-width: 990px) {
    .subTitle1 {
        text-align: start;
        font-size: 50px;
    }

    .subTitle2 {
        font-size: 80px;
    }

    .subTitle3 {
        font-size: 50px;
    }

    .subtitles {
        display: none !important;
    }
}

@media only screen and (max-width: 900px) and (min-width: 501px) {
    .GBTitle {
        font-size: 36px;
        text-align: center;
        margin: 5%;
    }
}

@media only screen and (max-width: 500px) {
    .GBTitle {
        font-size: 35px;
        text-align: center;
        font-weight: bold;
        margin: 0 11%;
    }
}

.GBtextList{
    font-size: 15pt;
}

@media only screen and (max-width: 400) {
    .GBtextList{
        font-size: 11.5pt;
    }
   
}

.GBFooterText{
    font-weight : 500 !important;
    font-size: 13pt !important;
    text-align : justify !important
}
.GBheaderText{
    font-weight: 600 !important;  
    font-size: 15pt !important;
    text-align : justify !important
}



.invalidState {
    font-family: 'Roboto';
    margin: 0 !important;
    color: #58aed6;
    font-size: 1.4em;
    font-weight: bold;
    text-align: center;
    min-height: 25px;
    margin-right: 30px;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 28px;
}

.font16{
    font-size: 16px !important;
}

.font12{
    font-size: 12px !important;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.btn, a.btn {
    color: #fff;
    font-size: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 10px;
}

label, select, input, p, .p {
    font-size: 16px;
}

.ribbon {
    padding: 10px 0;
    text-align: center;
    margin-bottom: 20px;
    min-height: 70px;
}

.ribbon h1 {
    color: #fff !important;
    font-size: 36px !important;
}

.ribbonGB {
    padding: 10px 0;
    text-align: center;
    margin-bottom: 20px;
    min-height: 70px;
}

.ribbonGB h1 {
    color: #fff !important;
    font-size: 36px !important;
}

@media only screen and (max-width: 550px) {
    .ribbonGB h1 {
        font-size: 27px !important;
    }
}

.pre-loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
}

.pre-loader-img {
    position: fixed;
    text-align: center;
    top: 48%;
    z-index: 9999;
    font-size: 72px;
    width: 100%;
}

.preloader.solid {
    background: #fff;
}

.preloader.opaque {
    background: rgba(0, 0, 0, 0.25);
}

.pre-loader-opaque {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #fff;
    z-index: 9999;
}

.check-fields {
    margin-top: 20px;
}

.userform {
    /* padding-top: 30px; */
    padding-bottom: 30px;
}

    .userform.shaded {
        background-color: #eee;
    }

    .userform.lite {
        background-color: #f7faff;
    }

.input-fields {
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
}

    .input-fields input {
        border-radius: 5px;
    }

.card-img {
    margin-top: 20px;
}

.feature-item {
    margin-top: 15px;
}

.feature-bullet {
    font-size: 12px;
    padding-top: 6px;
    padding-right: 10px;
    vertical-align: top;
    display: table-cell;
    width: 5%;
}

.feature-title {
    display: table-cell;
    width: 95%;
}

.feature-desc {
    white-space: pre-line;
}

.spouse-info-green-text {
    color: #92c446;
}

.whitebg {
    background-color: #fff;
}

.shadedbg {
    background-color: #eee;
}

.print-ico {
    font-size: 36px;
    padding: 20px;
}

.print-text, .print-ico {
    display: table-cell;
    vertical-align: middle;
}

.spacer {
    margin-top: 20px;
    margin-bottom: 20px;
}

.mtop-30 {
    margin-top: 30px;
}

.mbot-30 {
    margin-bottom: 30px;
}

.text-center{
    text-align:center;
}

.button-link {
    background: none;
    border: 0;
    color: #069;
    cursor: pointer;
    padding: 0;
}

.button-link:hover {
    background: none;
    text-decoration: underline;
}

.disclaimer-smaller {
    font-size: 0.85em;
}

.benefits {
    margin-top: -5%
}

@media only screen and (max-width: 1000px) and (min-width: 580px) {
    .benefits {
        margin-top: -7%
    }
}

@media only screen and (max-width: 749px) {
    .marginMobile{
        margin-left: 5vw
    }

    .modal{
        margin-left: 5%;
        max-width: 90%;
    }
}

#e-consent-modal{
   max-width: 500px;
   max-height: 80vh;
}

#invalid-state-modal button {
    width: 60%;
  }
  

.clickToClose{
    position: absolute;
    top: 0;
    right: 4%;
    font-weight: 100;
}

@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .marginMobile{
        margin-left: 18vw
    }

}

@media only screen and (min-width: 1001px) {
    .marginMobile{
        margin-left: 32%
    }
}
@media only screen and (min-width: 1200px) {
   
    

    .otherOptionBase{
    
        padding: 2%;
        display: block;
        width: 100%;
        background-color: #2d8be9;
        border-radius: 25px;
    
    }
    
    .otherCardArrow{
        float: right;
        color: white;
        top: -3px;
        font-size: 46px;
        position: absolute;
        right: 1%;
    }
    
    .otherCardsLink{
        background-color: #51a7fe;
        border-radius: 25px;
        color: #51a7fe;
        right: 1%;
        position: absolute;
        width: 55%;
        font-size: 22pt;
        top: 12%;
    }
}

@media only screen and (max-width: 1199px) {
   
  

    .otherOptionBase{
    
        padding: 2%;
        display: block;
        width: 100%;
        background-color: #2d8be9;
        border-radius: 25px;
    
    }
    
    .otherCardArrow{
        float: right;
        color: white;
        top: -8px;
        font-size: 47px;
        position: absolute;
        right: 1%;
    }
    
    .otherCardsLink{
        background-color: #51a7fe;
        border-radius: 25px;
        color: #51a7fe;
        right: 1%;
        position: absolute;
        width: 55%;
        font-size: 21pt;
        top: 10%;
    }
}

@media only screen and (max-width: 992px) {
   
 

    .otherOptionBase{
    
        padding: 2%;
        display: block;
        width: 100%;
        background-color: #2d8be9;
        border-radius: 25px;
    
    }
    
    .otherCardArrow{
        float: right;
        color: white;
        top: -8px;
        font-size: 47px;
        position: absolute;
        right: 1%;
    }
    
    .otherCardsLink{
        background-color: #51a7fe;
        border-radius: 25px;
        color: #51a7fe;
        right: 1%;
        position: absolute;
        width: 55%;
        font-size: 21pt;
        top: 10%;
    }
}

@media only screen and (max-width: 992px) {
    .btn {
        padding: 10px 0;
        font-size: 21px;
        width: 100%;
        font-size: 16px;
    }

    .userform {
        padding-top: 0;
    }
 
    .mobileMaxWidth{
        width: 100%;
        display: block;
    }

    .otherOptionBase{
    
        padding: 2%;
        display: block;
        width: 100%;
        background-color: #2d8be9;
        border-radius: 25px;
        cursor: pointer;
    
    }
    
    .otherCardArrow{
        float: right;
        color: white;
        top: -7px;
        font-size: 39px;
        position: absolute;
        right: 1%;
    }
    
    .otherCardsLink{
        background-color: #51a7fe;
        border-radius: 25px;
        color: #51a7fe;
        right: 1%;
        position: absolute;
        width: 49%;
        font-size: 18pt;
        top: 8%;
    }
}


.offerMessage{
    color: white;
    font-weight: bolder;
    margin-left: 14%;
    font-size: 13pt;
}

.SpecialOfferText{
    font-family: inherit !important;
    font-weight: bold !important;
    font-size: 25pt !important;
}

.otherOptionBase{
    cursor: pointer;
}

.baseOffer{
    background-color: #e4e9eb;
    border-radius: 10px;
    padding: 1%;
}



.modal-content:has(.MfaTitle){
    border-radius: 1.3rem;
}

 /* Input container */
 .input-container-modal {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

/* Individual input fields */
.mfaInputs {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.fakeLink {
    color: #0366d6; /* Change this with links color*/
    cursor: pointer;
    text-decoration: underline;
}

.fakeLink:hover {
    color: #444444; /* Change the value to with anchors hover color*/
}

.MfaSmallerText p{
    font-size: 0.8em;
}

.MfaEmailButton{
    max-width: 65%;
}

.font18 p{
    font-size: 16px;
}

@media only screen and (max-width: 749px) {
    .font18 p{
        font-size: 14px;
    }
    .MfaTitle p{
        font-size: 1.4em !important;
    }

    .MfaTitle{
        font-size: 1.4em !important;
    }
}

.MfaTitle{
    text-align: center;
    font-weight: bold;
    font-size: 2em ;
    line-height: normal;
}
.mfabutton{
    font-weight: bolder;
    padding: 2% 15%;
}

.mfaDisabled{
    color: black !important;
    border-color: lightgray  !important;
    background-color: lightgray  !important;
}

#mfa-modal-end{
    border-radius: 10%;
}
